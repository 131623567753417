export const getApplicationData = (appId) => (state) => state.application.extendedData[appId];

export const getApplicationDependencies = (appId) => (state) => state.application.dependencies[appId];

export const getApplicationComponents = (appId) => (state) => state.application.components[appId];

export const getApplicationByInsight = (insight) => (state) => {
  if (insight?.resource.type === 'application') {
    const appId = insight.resource.id;
    return state.application.extendedData[appId];
  }
  return null;
};

export const isAllApplicationDataFinishedLoading = (state) =>
  !(
    state.application.relatedFlowsLoading ||
    state.application.usedByAppsLoading ||
    state.application.usersLoading ||
    state.application.connectionsLoading ||
    state.application.singleLoading ||
    state.insights.appInsights.loading
  );

export const isApplicationRelatedFlowsLoading = (state) => state.application.relatedFlowsLoading;
export const getApplicationRelatedFlows = (appId) => (state) => state.application.relatedFlows[appId];
export const getApplicationUsers = (appId) => (state) => state.application.users[appId] || [];
export const isUsedByApplicationsLoading = (state) => state.application.usedByAppsLoading;
export const getUsedByApplications = (appId) => (state) => state.application.usedByApps[appId];
export const getApplicationSummaries = (state) => state.application.summaries;
export const getTotalApplicationsCount = (state) => state.application.total.count;
export const isApplicationsLoading = (state) => state.application.loading;
export const isSingleApplicationLoading = (state) => state.application.singleLoading;
export const getApplicationFilters = (state) => state.application.filters;
export const getApplicationFiltersOptions = (state) => state.application.filtersOptions;
export const getApplicationConnections = (appId) => (state) => state.application.connections[appId];
export const getApplicationsGroupsCounters = (state) => state.application.grouped.content;
export const isApplicationsGroupsCountersLoading = (state) => state.application.grouped.loading;
export const getApplicationDataAccessAggregations = (state) => state.application.dataAccessAggregations.content;
export const isApplicationDataAccessAggregationsLoading = (state) => state.application.dataAccessAggregations.loading;
