import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { fetchSingleAggregation } from '../../redux/slicers/insightSlicer';
import { getInsightAggregationsLoading, getSingleAggregation } from '../../redux/selector/insightsSelector';
import Card from '../../components/card/Card';
import Segment from '../../components/segment/Segment';
import KeyValueElement from '../../components/key-value-element/KeyValueElement';
import PlatformName from '../../components/platform-name/PlatformName';
import InsightScope from '../../components/insight-scope/InsightScope';
import Remediation from '../../components/remediation/Remediation';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import InsightAttributes from '../../components/attribute/InsightAttributes';
import SingleInsightsTable from '../insights/SingleInsightsTable';
import DoughnutChart from '../../components/chart/doughnut/DoughnutChart';
import ResolutionWidget from './ResolutionWidget';
import { mapDate } from '../../services/MapperUtils';
import { extractDataFromAggregationIdentity } from '../../services/aggregationUtils';
import { highColor, mediumColor, trueColor } from '../../consts/colors';

import './single-aggregation.scss';

function getClosedTime(insights, activeInsightsCount) {
  if (insights.length || activeInsightsCount > 0) {
    return null;
  }

  const sortedInsights = [...insights].sort(
    (a, b) => new Date(a.lastChangeTime).getTime() - new Date(b.lastChangeTime).getTime(),
  );
  return sortedInsights.at(-1).lastChangeTime;
}

export default function SingleAggregationPage() {
  const dispatch = useDispatch();
  const { aggId } = useParams();

  const aggregation = useSelector(getSingleAggregation(aggId));
  const loading = useSelector(getInsightAggregationsLoading);
  const [selectedInsights, setSelectedInsights] = useState([]);

  const { t } = useTranslation(['insight-aggregation', 'common', 'remediation']);

  const isReady = aggregation && !loading;

  useEffect(() => {
    (async function fetchData() {
      if (!aggregation) {
        dispatch(fetchSingleAggregation(aggId));
      }
    })();
  }, [dispatch, aggId]);

  return (
    <div className="single-aggregation">
      <Breadcrumbs current={aggregation?.name} />
      <Segment title={t('aggregationIdentity')}>
        {isReady ? (
          <AggregationIdentity aggregation={aggregation} />
        ) : (
          <Skeleton animation="wave" variant="rectangular" width="95%" />
        )}
      </Segment>
      <Segment title={t('aggregationData')}>
        {isReady ? (
          <AggregationData aggregation={aggregation} />
        ) : (
          <Skeleton animation="wave" variant="rectangular" width="95%" />
        )}
      </Segment>
      <AggregationWidgets
        insights={aggregation?.summaryInsights.data}
        loading={!isReady}
        createdAt={aggregation?.createdAt}
      />
      <Remediation
        remediationActions={aggregation?.remediationActions}
        text={aggregation?.remediation}
        acknowledgeText={t('acknowledgeSelectedInsights', { ns: 'remediation' })}
        insights={aggregation?.insights.filter((i) => selectedInsights.includes(i.id))}
        onAcknowledge={() => dispatch(fetchSingleAggregation(aggId))}
        accountId={aggregation?.accountId}
      />

      <Segment title={t('insights.insights', { ns: 'common' })} background={false}>
        <SingleInsightsTable
          data={aggregation?.summaryInsights.data || []}
          totalInsights={aggregation?.insights.totalCount}
          backendPagination={false}
          showAcknowledgeButton={false}
          showFilter={false}
          loading={!isReady}
          onRowSelected={setSelectedInsights}
        />
      </Segment>
    </div>
  );
}

function AggregationWidgets({ createdAt, insights = [], loading }) {
  const { t } = useTranslation(['insight-aggregation', 'common']);

  return (
    <div className="aggregation-widgets row">
      <Card title={t('aggregatedInsightsCharts.resolvedVSOpen')}>
        <InsightStatusWidget insights={insights} loading={loading} />
      </Card>
      <Card title={t('aggregatedInsightsCharts.resolutionByEnvironment')}>
        <ResolutionWidget insights={insights} createdAt={createdAt} loading={loading} />
      </Card>
    </div>
  );
}

function InsightStatusWidget({ insights = [], loading }) {
  const { t } = useTranslation(['common']);

  const resolvedCount = insights.filter((x) => x.status.toLowerCase() === 'closed resolved').length;
  const acknowledgedCount = insights.filter((x) => x.status.toLowerCase() === 'closed acknowledged').length;
  const openCount = insights.length - resolvedCount - acknowledgedCount;

  const data = {
    labels: [t('insights.resolved'), t('insights.acknowledged'), t('insights.open')],
    datasets: [
      {
        data: [resolvedCount, acknowledgedCount, openCount],
        backgroundColor: [trueColor, mediumColor, highColor],
        borderWidth: 3,
      },
    ],
  };

  return <DoughnutChart id="status" data={data} loading={loading} width={150} height={150} legendPercentage />;
}

function AggregationIdentity({ aggregation }) {
  const pairs = extractDataFromAggregationIdentity(aggregation?.identity);
  const { t } = useTranslation(['common']);

  return (
    <div className="agg-identity">
      <div className="row">
        <KeyValueElement flex={3} value={aggregation.name} />
        <KeyValueElement
          flex={1}
          title={t('general.platform')}
          value={<PlatformName platform={aggregation.platformName} />}
        />
        <KeyValueElement
          flex={1}
          title={t('insights.scope')}
          value={<InsightScope insightScopeSubType={aggregation.scope} />}
        />
      </div>
      <InsightAttributes attributes={pairs.map(([key, value]) => ({ title: key, value }))} />
    </div>
  );
}

function AggregationData({ aggregation }) {
  const { t } = useTranslation(['insight-aggregation']);

  const insightsStart = aggregation.summaryInsights.data.length;
  const insightsNow = aggregation.summaryInsights.data.filter((i) => !i.status.startsWith('Closed')).length;

  const startAt = mapDate(aggregation.createdAt);
  const closedTime = getClosedTime(aggregation.summaryInsights, insightsNow);
  const closedAtAt = closedTime ? mapDate(closedTime) : t('aggregatedInsightsData.notClosedYet');

  return (
    <div className="row">
      <KeyValueElement title={t('aggregatedInsightsData.insightsAtBeginning')} value={insightsStart} />
      <KeyValueElement title={t('aggregatedInsightsData.insightsNow')} value={insightsNow} />
      <KeyValueElement title={t('aggregatedInsightsData.detectionDate')} value={startAt} />
      <KeyValueElement title={t('aggregatedInsightsData.closeDate')} value={closedAtAt} />
    </div>
  );
}
