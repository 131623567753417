import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMsal } from '@azure/msal-react';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import CloseableModal from '../../../../../components/modal/ClosableModal';
import Button from '../../../../../components/buttons/button/Button';
import { getOnboardingToken } from '../../../../../auth/azure-auth-config';
import { fetchPowerPlatformEnvironments } from '../../../../../redux/slicers/accountSlicer';
import Loader from '../../../../../components/loader/Loader';
import {
  getPowerPlatformIntegrationErrorMessage,
  isPowerPlatformIntegrationLoading,
} from '../../../../../redux/selector/accountsSelector';
import PowerPlatformIntegrationError from '../PowerPlatformIntegrationError';

export default function PowerPlatformIntegrationGetEnvironmentsModal({ open, setOpen }) {
  const { accountId } = useParams();
  const { t } = useTranslation(['onboarding', 'common']);
  const dispatch = useDispatch();
  const isLoading = useSelector(isPowerPlatformIntegrationLoading);
  const serverError = useSelector(getPowerPlatformIntegrationErrorMessage);
  const { user } = useAuth0();

  const [azureError, setAzureError] = useState(null);

  const error = serverError || azureError;
  const isConditionalAccessError = error && error.includes('AADSTS53003:');

  const { instance } = useMsal();

  async function getTokenAndFetchEnvironments() {
    try {
      const result = await getOnboardingToken(instance);

      await dispatch(
        fetchPowerPlatformEnvironments({
          accountId,
          adminAccessToken: result.accessToken,
          email: user.email,
        }),
      );
    } catch (e) {
      setAzureError(e.message);
    }
  }

  return (
    <CloseableModal
      open={open}
      closeModal={() => {
        setOpen(false);
      }}
    >
      <div className="title">{t('fetchEnvironments.title')}</div>
      {isLoading && <Loader />}
      {error && !isConditionalAccessError && (
        <div className="error centered">
          <div className="emoji">💔</div>
          <div>{t('fetchEnvironments.title')}</div>
          <div>{error}</div>
        </div>
      )}
      {isConditionalAccessError && (
        <div className="error centered">
          <div className="emoji">💔</div>
          <PowerPlatformIntegrationError />
        </div>
      )}
      {!isLoading && !error && (
        <>
          <div className="text-container">{t('fetchEnvironments.message')}</div>
          <div className="buttons-row">
            <Button isFilled={false} text={t('general.cancel', { ns: 'common' })} onClick={() => setOpen(false)} />
            <Button isFilled text={t('general.approve', { ns: 'common' })} onClick={getTokenAndFetchEnvironments} />
          </div>
        </>
      )}
    </CloseableModal>
  );
}
