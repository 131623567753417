import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useMsal } from '@azure/msal-react';
import Markdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Loader from '../loader/Loader';
import Button from '../buttons/button/Button';
import CloseableModal from '../modal/ClosableModal';
import {
  getInsightRemediationContent,
  getInsightRemediationError,
  getInsightRemediationLoading,
} from '../../redux/selector/insightsSelector';
import { getLoginRequest } from '../../auth/azure-auth-config';
import { clearRemediation, fetchSingleInsight, remediateInsight } from '../../redux/slicers/insightSlicer';
import { INTERNAL_SERVER_ERROR } from '../../consts/erros';

export function LinkRenderer({ href, children }) {
  return (
    <a href={href} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
}

export default function AutoRemediateModal({
  platform,
  modelOpen,
  setModalOpen,
  remediationActions = '',
  scopeSubType,
  isDemo,
  insightId,
}) {
  const dispatch = useDispatch();
  const { instance } = useMsal();
  const remediationResult = useSelector(getInsightRemediationContent);
  const isLoading = useSelector(getInsightRemediationLoading);
  const error = useSelector(getInsightRemediationError);
  const remediationSuccess = !isEmpty(remediationResult);
  const { t } = useTranslation(['remediation', 'common']);

  useEffect(() => {
    if (remediationSuccess) {
      dispatch(fetchSingleInsight(insightId));
    }
  }, [remediationSuccess]);

  useEffect(
    () => () => {
      dispatch(clearRemediation());
    },
    [modelOpen],
  );

  async function handleRemediate() {
    try {
      // todo: remove when applied to all insights with remediationActions
      if (isDemo) {
        setModalOpen(false);
      } else {
        const result = await instance.loginPopup(getLoginRequest(scopeSubType));
        dispatch(remediateInsight({ accessToken: result.accessToken, insightId, email: result.account.username }));
      }
    } catch (e) {
      console.error(e);
    }
  }

  function renderRemediation() {
    return (
      <>
        <div className="text-container">
          {t('remediationModal.remediationConfirmMessage', { platform })}
          <div className="text">
            {(remediationActions || '').split('\n').map((line) => (
              <Markdown key={line} components={{ a: LinkRenderer }}>
                {line}
              </Markdown>
            ))}
          </div>
        </div>
        <div className="buttons-row">
          <Button text={t('general.cancel', { ns: 'common' })} onClick={() => setModalOpen(false)} isFilled={false} />
          <Button text={t('general.approve', { ns: 'common' })} onClick={handleRemediate} isFilled />
        </div>
      </>
    );
  }

  return (
    <CloseableModal open={modelOpen} closeModal={() => setModalOpen(false)}>
      <div className="title">{t('remediationModal.title')}</div>
      {isLoading && <Loader />}
      {error && (
        <div className="error centered">
          <div className="remediation-emoji">🩹</div>
          <div>{t('remediationResult.failedRemediation')}</div>
          <div>{error}</div>
          <br />
          {error === INTERNAL_SERVER_ERROR && (
            <div className="text-button">
              <button type="button" className="link" onClick={handleRemediate}>
                {t('general.pleaseTryAgain', { ns: 'common' })}
              </button>
            </div>
          )}
        </div>
      )}
      {remediationSuccess && (
        <div className="success centered">
          <div className="remediation-emoji">🏆</div>
          <div>{t('remediationResult.remediationSuccess')}</div>
        </div>
      )}
      {!isLoading && !error && !remediationSuccess && renderRemediation()}
      <div className="buttons-row">
        {(error || remediationSuccess) && (
          <Button text={t('general.done', { ns: 'common' })} onClick={() => setModalOpen(false)} isFilled />
        )}
      </div>
    </CloseableModal>
  );
}
