import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { kebabCase } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import KeyOutlined from '@mui/icons-material/KeyOutlined';
import { useState } from 'react';
import { getDefaultEnvironmentsByAccountId, isEnvironmentsLoading } from '../../../redux/selector/environmentsSelector';
import { mapDate } from '../../../services/MapperUtils';
import PlatformName from '../../../components/platform-name/PlatformName';
import { deleteAccount, renameAccount } from '../../../redux/slicers/accountSlicer';
import SimpleError from '../../../components/simple-error/SimpleError';
import { getAccountRenameError, getAccountRenameLoading } from '../../../redux/selector/accountsSelector';
import OrganizationAwareLink from '../../../components/organization-aware-link/OrganizationAwareLink';
import { POWER_PLATFORM } from '../../../consts/platforms';
import { isPermitted } from '../../../redux/selector/authSelector';
import EditAccountCredentialsModal from './power-platform/edit-account-modal/EditAccountCredentialsModal';

export default function AccountCard({ account }) {
  const { t } = useTranslation(['settings']);
  const { user } = useAuth0();
  const dispatch = useDispatch();
  const { platformName, creationTime, id, name } = account;
  const environments = useSelector(getDefaultEnvironmentsByAccountId(id));
  const environmentsLoading = useSelector(isEnvironmentsLoading);
  const renameLoading = useSelector(getAccountRenameLoading(account.id));
  const error = environmentsLoading || environments.length ? null : t('platforms.accounts.errors.noEnvironments');
  const renameError = useSelector(getAccountRenameError(id));
  const canEditEnvironments = useSelector(isPermitted('environments:update'));
  const canRenameAccount = useSelector(isPermitted('accounts:rename'));
  const canDeleteAccount = useSelector(isPermitted('accounts:delete'));
  const isPowerPlatform = account.platformName === POWER_PLATFORM;

  const editDisabled = !isPowerPlatform || !canEditEnvironments;

  const [editCredentialsModalOpen, setEditCredentialsModalOpen] = useState(false);

  async function handlePlatformRename(newName) {
    await dispatch(
      renameAccount({
        accountId: id,
        body: { name: newName, auditUserName: user.name, auditUserEmail: user.email },
      }),
    );
  }

  async function handleDeleteAccount() {
    if (canDeleteAccount) {
      await dispatch(
        deleteAccount({
          accountId: id,
          body: { auditUserName: user.name, auditUserEmail: user.email },
        }),
      );
    }
  }

  return (
    <div className={classnames('platform-card', 'account-card', { error })}>
      <PlatformName
        name={name}
        platform={platformName}
        editable={canRenameAccount}
        onRename={handlePlatformRename}
        loading={renameLoading}
        toastSuccessful={!renameError}
        toastTitle={t(`platforms.accounts.rename${renameError ? 'Error' : 'Success'}`)}
      />
      <div className="account-card-props">
        <div className="account-card-props-entry">
          <div className="account-card-props-entry-title">{t('platforms.accounts.creationTime')}</div>
          <div className="account-card-props-entry-value">{mapDate(creationTime)}</div>
        </div>
        <div className="account-card-props-entry">
          <div className="account-card-props-entry-title">{t('platforms.accounts.environments')}</div>
          <div className="account-card-props-entry-value">{environments.length}</div>
        </div>
      </div>
      <SimpleError error={error} />
      <div className="account-card-actions">
        <span
          className={classnames('account-card-actions-item', { disabled: editDisabled })}
          onClick={() => setEditCredentialsModalOpen(true)}
        >
          {!editDisabled && (
            <EditAccountCredentialsModal
              account={account}
              modalOpen={editCredentialsModalOpen}
              closeModal={() => setEditCredentialsModalOpen(false)}
            />
          )}
          <KeyOutlined />
          {t('platforms.accounts.actions.credentials')}
        </span>
        <span className={classnames('account-card-actions-item', { disabled: editDisabled })}>
          <OrganizationAwareLink
            to={`/settings/platforms/${kebabCase(account.platformName)}/${id}/edit?from=Platforms`}
            disabled={editDisabled}
          >
            <EditIcon onClick={() => {}} />
            {t('platforms.accounts.actions.edit')}
          </OrganizationAwareLink>
        </span>
        <span className={classnames('account-card-actions-item', { disabled: true })} onClick={handleDeleteAccount}>
          <DeleteOutlineOutlinedIcon />
          {t('platforms.accounts.actions.delete')}
        </span>
        <span className={classnames('account-card-actions-item', { disabled: true })}>
          <RemoveRedEyeOutlinedIcon onClick={() => {}} />
          {t('platforms.accounts.actions.view')}
        </span>
      </div>
    </div>
  );
}
