/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { doGet } from '../../services/HttpService';
import {
  enrichApplicationFilters,
  getApplicationBackendKeyForOrderBy,
  mapFiltersToParams,
  mapPropertiesToParams,
} from '../../services/MapperUtils';

async function sendSummaryRequest(filters, orderBy, order, page) {
  const params = mapPropertiesToParams(filters, orderBy, order, page);
  const path = params.toString() ? `application/summary?${params.toString()}` : `application/summary`;
  const response = await doGet(path);

  return response.data;
}

export const fetchApplicationData = createAsyncThunk('application/fetchInsights', async (appId) => {
  const res = await doGet(`application/${appId}`);
  return res.data;
});

export const fetchApplicationSummaries = createAsyncThunk(
  'application/fetchSummaries',
  async ({ filters, orderBy, order, page } = []) => sendSummaryRequest(filters, orderBy, order, page),
);

export const fetchApplicationGroupedCounters = createAsyncThunk('application/fetchGroupedCounters', async (filters) => {
  const enrichedFilters = enrichApplicationFilters(filters);
  const params = mapFiltersToParams(enrichedFilters);

  const groups = ['platform', 'inProduction'];
  const promises = groups.map(async (group) => {
    const response = await doGet(`application/summary/grouped/${group}?${params.toString()}`);
    return response.data;
  });

  const [platformRes, inProductionRes] = await Promise.all(promises);

  return {
    platform: platformRes,
    inProduction: inProductionRes,
  };
});

export const fetchTotalApplications = createAsyncThunk('application/fetchTotal', async (filters) => {
  const enrichedFilters = enrichApplicationFilters(filters);
  return sendSummaryRequest(enrichedFilters);
});

export const updateApplicationFiltersAndRefetchSummaries = createAsyncThunk(
  'application/updateFiltersAndRefetchSummaries',
  async ({ filters, orderBy, order, page }, { dispatch }) => {
    const enrichedFilters = enrichApplicationFilters(filters, orderBy);
    const enrichedOrderBy = getApplicationBackendKeyForOrderBy(orderBy);

    dispatch(fetchApplicationSummaries({ filters: enrichedFilters, orderBy: enrichedOrderBy, order, page }));
    return enrichedFilters;
  },
);

export const fetchApplicationFilterOptions = createAsyncThunk('application/fetchFilterOptions', async () => {
  const response = await doGet(`application/summary/filters`);
  return response.data;
});

export const fetchApplicationRelatedFlows = createAsyncThunk('application/fetchRelatedFlows', async (appId) => {
  const response = await doGet(`application/${appId}/flows`);
  return { id: appId, flows: response.data };
});

export const fetchApplicationUsers = createAsyncThunk('application/fetchUsers', async (appId) => {
  const response = await doGet(`application/${appId}/users`);
  return { id: appId, users: response.data };
});

export const fetchUsedByApplications = createAsyncThunk('application/fetchUsedByApplications', async (appId) => {
  const response = await doGet(`application/${appId}/usedBy`);
  return { id: appId, usedBy: response.data };
});

export const fetchApplicationDependencies = createAsyncThunk('application/fetchDependencies', async (appId) => {
  const response = await doGet(`application/${appId}/dependencies`);
  return {
    id: appId,
    dependencies: response.data,
  };
});

export const fetchApplicationComponents = createAsyncThunk('application/fetchComponents', async (appId) => {
  const response = await doGet(`application/${appId}/components`);
  return {
    id: appId,
    components: response.data,
  };
});

export const fetchDataAccessAggregations = createAsyncThunk('application/fetchDataAccessAggregations', async () => {
  const response = await doGet(`application/data-access`);
  return response.data;
});

export const fetchApplicationConnections = createAsyncThunk(
  'application/fetchApplicationConnections',
  async (appId) => {
    const res = await doGet(`application/${appId}/connections`);

    return {
      connections: res.data,
      appId,
    };
  },
);

const relatedFlowsInitialState = {
  relatedFlows: {},
  relatedFlowsLoading: false,
};

const appUsersInitialState = {
  users: {},
  usersLoading: false,
};

const usedByAppsInitialState = {
  usedByApps: {},
  usedByAppsLoading: false,
};

const appConnectionsInitialState = {
  connections: {},
  connectionsLoading: false,
  connectionsError: false,
};

const initialState = {
  loading: true,
  error: false,
  singleLoading: false,
  singleError: false,
  applications: [],
  summaries: [],
  grouped: {
    loading: false,
    error: false,
    content: {},
  },
  dataAccessAggregations: {
    loading: false,
    error: false,
    content: [],
  },
  total: {
    count: 0,
    content: [],
  },
  extendedData: {},
  dependencies: {},
  components: {},
  filtersOptions: {},
  filters: [],
  ...relatedFlowsInitialState,
  ...usedByAppsInitialState,
  ...appConnectionsInitialState,
  ...appUsersInitialState,
};

export const applicationsSlice = createSlice({
  name: 'applications',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchDataAccessAggregations.pending, (state) => {
      state.dataAccessAggregations.loading = true;
      state.dataAccessAggregations.error = false;
    });
    builder.addCase(fetchDataAccessAggregations.fulfilled, (state, { payload }) => {
      state.dataAccessAggregations.loading = false;
      state.dataAccessAggregations.error = false;
      state.dataAccessAggregations.content = payload;
    });
    builder.addCase(fetchDataAccessAggregations.rejected, (state) => {
      state.dataAccessAggregations.loading = false;
      state.dataAccessAggregations.error = true;
    });
    builder.addCase(fetchApplicationGroupedCounters.fulfilled, (state, { payload }) => {
      state.grouped.loading = false;
      state.grouped.error = false;
      state.grouped.content = payload;
    });
    builder.addCase(fetchApplicationGroupedCounters.pending, (state) => {
      state.grouped.loading = true;
      state.grouped.error = false;
    });
    builder.addCase(fetchApplicationGroupedCounters.rejected, (state) => {
      state.grouped.loading = false;
      state.grouped.error = true;
    });
    builder.addCase(fetchApplicationFilterOptions.fulfilled, (state, { payload }) => {
      state.filtersOptions = payload;
    });
    builder.addCase(fetchApplicationData.pending, (state) => {
      state.singleLoading = true;
      state.singleError = false;
    });
    builder.addCase(fetchApplicationData.fulfilled, (state, { payload }) => {
      state.singleLoading = false;
      state.singleError = false;
      state.extendedData[payload.id] = payload;
    });
    builder.addCase(fetchApplicationData.rejected, (state) => {
      state.singleLoading = false;
      state.singleError = true;
    });
    builder.addCase(fetchApplicationSummaries.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(fetchApplicationSummaries.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.summaries = payload.data;
      state.total.count = payload.totalCount;
    });
    builder.addCase(fetchTotalApplications.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.total.content = payload.data;
    });
    builder.addCase(fetchApplicationSummaries.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(updateApplicationFiltersAndRefetchSummaries.fulfilled, (state, { payload }) => {
      state.filters = payload;
    });

    builder.addCase(fetchApplicationRelatedFlows.pending, (state) => {
      state.relatedFlowsLoading = true;
    });
    builder.addCase(fetchApplicationRelatedFlows.fulfilled, (state, { payload }) => {
      state.relatedFlows[payload.id] = payload.flows;
      state.relatedFlowsLoading = false;
    });

    builder.addCase(fetchApplicationUsers.pending, (state) => {
      state.usersLoading = true;
    });
    builder.addCase(fetchApplicationUsers.fulfilled, (state, { payload }) => {
      state.users[payload.id] = payload.users;
      state.usersLoading = false;
    });
    builder.addCase(fetchApplicationUsers.rejected, (state) => {
      state.usersLoading = false;
    });

    builder.addCase(fetchUsedByApplications.pending, (state) => {
      state.usedByAppsLoading = true;
    });
    builder.addCase(fetchUsedByApplications.fulfilled, (state, { payload }) => {
      state.usedByApps[payload.id] = payload.usedBy;
      state.usedByAppsLoading = false;
    });

    builder.addCase(fetchApplicationConnections.pending, (state) => {
      state.connectionsLoading = true;
      state.connectionsError = false;
    });
    builder.addCase(fetchApplicationConnections.fulfilled, (state, { payload }) => {
      state.connectionsLoading = false;
      state.connectionsError = false;
      state.connections[payload.appId] = payload.connections;
    });
    builder.addCase(fetchApplicationConnections.rejected, (state) => {
      state.connectionsLoading = false;
      state.connectionsError = true;
    });

    builder.addCase(fetchApplicationDependencies.fulfilled, (state, { payload }) => {
      state.dependencies[payload.id] = payload.dependencies;
    });

    builder.addCase(fetchApplicationComponents.fulfilled, (state, { payload }) => {
      state.components[payload.id] = payload.components;
    });
  },
  reducers: {
    clearApplication: () => ({ ...initialState }),
  },
});

// this is for configureStore
export const { clearApplication } = applicationsSlice.actions;
export default applicationsSlice.reducer;
