import { useEffect, useMemo } from 'react';
import { startCase } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  getConnectors,
  getConnectorsFilters,
  getConnectorsFiltersOptions,
  getTotalConnectors,
  isConnectorsLoading,
} from '../../redux/selector/dataSourcesSelector';
import OrganizationAwareLink from '../../components/organization-aware-link/OrganizationAwareLink';
import { getConnectorIcon } from './connector-icons/connectorsIconsService';
import Table from '../../components/table/Table';
import {
  fetchConnectorsFiltersOptions,
  updateConnectorsFiltersAndRefetchSummaries,
} from '../../redux/slicers/dataSourceSlicer';
import TableEmptyContent from '../../components/table/TableEmptyContent';
import { mapConnectorFilter, mapConnectorFilterValue, mapDate } from '../../services/MapperUtils';
import ConnectorStatus from './ConnectorStatus';
import InternalLink from '../../components/internal-link/InternalLink';
import ExternalLink from '../../components/external-link/ExternalLink';

import emptyApplicationsImg from '../inventory/empty_applications.svg';
import EllipsisText from '../../components/ellipsis-text/EllipsisText';
import { ColumnType } from '../../components/table/ColumnOptions';
import PlatformName from '../../components/platform-name/PlatformName';

export default function ConnectorsPage({ switchTab }) {
  const connectors = useSelector(getConnectors);
  const totalCount = useSelector(getTotalConnectors);
  const connectorsLoading = useSelector(isConnectorsLoading);
  const connectorFilterOptions = useSelector(getConnectorsFiltersOptions);
  const dispatch = useDispatch();

  const { t } = useTranslation(['common', 'connections']);

  useEffect(() => {
    (async function fetchData() {
      dispatch(fetchConnectorsFiltersOptions());
    })();
  }, []);

  const columns = useMemo(
    () => [
      {
        backendKey: 'name',
        width: '20%',
        type: ColumnType.STRING,
        accessor: 'name',
        Cell: ({ cell }) => {
          const image = getConnectorIcon(cell.value);
          const { connectorId } = cell.row.original;

          return (
            <div className="connector-name-cell">
              <OrganizationAwareLink
                to={`/data?tab=Connections&connectorId:is=${connectorId}`}
                className="black-link"
                onClick={() => switchTab()}
                replace
              >
                <img src={image} alt={cell.value} />
                <EllipsisText text={cell.value} />
              </OrganizationAwareLink>
            </div>
          );
        },
      },
      {
        backendKey: 'type',
        width: '15%',
        type: ColumnType.STRING,
        accessor: (cell) => cell.type,
        Cell: ({ cell }) => <div className="gray">{startCase(cell.value.toLowerCase())}</div>,
      },
      {
        backendKey: 'publisher',
        width: '20%',
        type: ColumnType.STRING,
        accessor: (cell) => cell.publisher,
        Cell: ({ cell }) => <EllipsisText text={cell.value} className="gray" />,
      },
      {
        backendKey: 'accountName',
        width: '20%',
        type: ColumnType.STRING,
        accessor: (cell) => cell.accountName,
        Cell: ({ value, cell }) => (
          <EllipsisText
            text={<PlatformName platform={cell.row.original.platformName} name={value} />}
            overrideTooltipText={value}
          />
        ),
      },
      {
        backendKey: 'documentation',
        accessor: (cell) => cell.documentation,
        Cell: ({ cell }) => {
          const documentation = cell.value;
          return <div className="gray">{documentation ? <ExternalLink href={documentation} /> : t('general.na')}</div>;
        },
      },
      {
        backendKey: 'status',
        width: '15%',
        accessor: (cell) => cell.status,
        Cell: ({ cell }) => <ConnectorStatus statusData={cell.row.original} />,
      },
      {
        backendKey: 'firstSeen',
        width: '28%',
        type: ColumnType.DATE,
        accessor: (cell) => cell.firstSeen,
        Cell: ({ cell }) => <div className="gray">{mapDate(cell.value)}</div>,
      },
      {
        backendKey: 'applicationsCount',
        type: ColumnType.NUMBER,
        accessor: (cell) => cell.applicationsCount,
        Cell: ({ cell }) => (
          <div className="connector-name-cell gray">
            <InternalLink text={cell.value} url={`/applications?connectorId:is=${cell.row.original.id}`} />
          </div>
        ),
      },
      {
        backendKey: 'connectionsCount',
        type: ColumnType.NUMBER,
        accessor: (cell) => cell.connectionsCount,
        Cell: ({ cell }) => (
          <div className="connector-name-cell gray">
            <InternalLink text={cell.value} url={`/data?tab=Connections&connectorName:is=${cell.row.original.name}`} />
          </div>
        ),
      },
    ],
    [],
  );

  const sortBy = [
    {
      id: 'Applications',
      desc: true,
    },
  ];

  return (
    <div className="connectors-page">
      <Table
        columns={columns}
        data={connectors}
        options={{ showFilter: true, showHeaders: true }}
        type="connectors"
        sortBy={sortBy}
        exportPath="connectors/summary/csv"
        exportFileName="Nokod Connectors"
        fetchDataWithFilters={(filters, orderBy, order, page) => {
          dispatch(updateConnectorsFiltersAndRefetchSummaries({ filters, orderBy, order, page }));
        }}
        getFiltersSelector={getConnectorsFilters}
        customFilters={[]}
        contextualActions={[]}
        excludeFilters={['documentation']} // fixme: support translations
        onRowSelected={() => {}}
        isRowDisabled={() => false}
        getElementId={(connector) => connector.id}
        filtersOptions={connectorFilterOptions}
        isLoading={connectorsLoading}
        mapFilterValue={mapConnectorFilterValue}
        mapFilterFunction={mapConnectorFilter}
        totalCount={totalCount}
        emptyState={
          <TableEmptyContent
            img={emptyApplicationsImg}
            mainText={t('noConnectors', { ns: 'connections' })}
            secondaryText={t('tables.makeDifferentFilterSelection')}
          />
        }
        defaultSearchColumn="name"
        defaultSearchColumnName={t('tables.connectors.columns.name')}
      />
    </div>
  );
}
