import { snakeCase, camelCase } from 'lodash';
import moment from 'moment';
import {
  AFTER,
  BEFORE,
  CONTAINS,
  GREATER_THAN,
  IS,
  IS_NOT,
  IS_NULL,
  LESS_THAN,
  OR_OPERATOR,
} from '../components/filter/filterConsts';
import { DEFAULT_TABLE_PAGE_SIZE } from '../consts/table';

const US_FORMAT = 'MM/DD/YYYY, hh:mm:ss A';
const EU_FORMAT = 'DD/MM/YYYY, hh:mm:ss A';

export function isUsLocale() {
  const userLocale = navigator.language;

  return userLocale === 'en-US';
}

export function mapChartTick(value) {
  let first;
  let second;
  let third;
  if (value.includes('/')) {
    [first, second, third] = value.split('/');
  } else {
    [first, second, third] = value.split('-');
  }

  if (!isUsLocale()) {
    return value;
  }

  return third ? `${second}/${third}` : `${first}/${second}`;
}

export function mapDate(dateString, format) {
  if (!dateString) return '';
  return moment(dateString).format(format || (isUsLocale() ? US_FORMAT : EU_FORMAT));
}

export function formatDateToDDMMYYYY(dateString) {
  return mapDate(dateString, isUsLocale() ? 'MM/DD/YYYY' : 'DD/MM/YYYY');
}

export function mapDataAccess({ tagNames }) {
  return tagNames.length ? tagNames.join(', ') : 'Untagged';
}

const mapFiltersTypeToOperation = {
  [IS_NULL]: 'isNull',
  [IS]: 'equals',
  [IS_NOT]: 'notEquals',
  [CONTAINS]: 'contains',
  [LESS_THAN]: 'lessThan',
  [BEFORE]: 'lessThan',
  [GREATER_THAN]: 'greaterThan',
  [AFTER]: 'greaterThan',
};

const applicationsColumnToBackendColumn = {
  'Application Name': 'name',
  Type: 'type',
  Platform: 'accountName',
  Owner: 'owner',
  'Last Change': 'lastChange',
  'In Production': 'inProduction',
  'Data Access': 'dataAccess',
  'Creation Time': 'createdAt',
  'Business Context': 'businessContextName',
  Insights: 'insights',
  'Connector ID': 'connectorId',
  'Connection ID': 'connectionId',
  'Data Source ID': 'dataSourceId',
  'Owner ID': 'ownerId',
  Dependency: 'dependency',
};

export const dataSourceColumnBackendColumn = {
  Applications: 'applicationCount',
  Environments: 'environments',
  Type: 'type',
  Name: 'name',
  Tag: 'tags',
  'Located In': 'dependsOnId',
  'In Production': 'inProduction',
};

export const insightsColumnToBackendColumn = {
  'Insight Name': 'name',
  Resource: 'resourceName',
  Type: 'type',
  Severity: 'severity',
  Status: 'status',
  'Creation Time': 'creationTime',
  'Last Change': 'lastChangeTime',
  'In Production': 'inProduction',
  Active: 'active',
  Resolved: 'resolved',
  'Resource Active': 'resourceActive',
  Scope: 'scopeSubType',
  Owner: 'ownerName',
  Platform: 'accountName',
  'Closed At': 'closedAt',
  'Owner ID': 'ownerId',
  'Application ID': 'scopeId',
  'Priority Score': 'score',
};

export const aggregationsColumnToBackendColumn = {
  Name: 'name',
  Platform: 'platformName',
  Type: 'insightType',
  Severity: 'severity',
  Status: 'status',
  'Time of Detection': 'createdAt',
  Scope: 'scope',
  Insights: 'insights',
};

export const connectorsColumnToBackendColumn = {
  Name: 'name',
  Type: 'type',
  Publisher: 'publisher',
  Platform: 'accountName',
  Status: 'deprecated', // FIXME, this works for deprecated vs non-deprecated but not for vetting
  'First Seen': 'firstSeen',
  Connections: 'connectionsCount',
  Applications: 'applicationsCount',
};

export const connectionsColumnToBackendColumn = {
  Name: 'name',
  Type: 'connectorName',
  Share: 'shareType',
  Tags: 'tags',
  'Create Time': 'creationTime',
  'Modify Time': 'updateTime',
  Environment: 'environments',
  'In Production': 'inProduction',
  Apps: 'applicationCount',
  Status: 'connectedStatus',
  Platform: 'accountName',
  'Owner ID': 'ownerId',
  'Connector ID': 'connectorId',
};

export const usersColumnToBackendColumn = {
  'User Name': 'name',
  'User Email': 'email',
  'Last Activity Time': 'lastActivity',
  'Last Login Time': 'lastLogin',
  Environment: 'environmentNames',
  Applications: 'applicationsCount',
  Connections: 'connectionsCount',
  Insights: 'insightsCount',
  Active: 'active',
  Guest: 'guest',
};

export const powerBiColumnToBackendColumn = {
  Name: 'name',
  Type: 'type',
  'Creation Time': 'creationTime',
  'Last Change': 'lastModified',
  Workspace: 'workspace',
  Public: 'public',
  'Shared With All': 'sharedWithOrg',
  Owner: 'owner',
};

export function enrichInsightFilters(filters) {
  return filters.map((filter) => ({
    ...filter,
    backendColumn: insightsColumnToBackendColumn[filter.column],
  }));
}

export function getInsightBackendKeyForOrderBy(orderBy) {
  return insightsColumnToBackendColumn[orderBy];
}

export function enrichDataSourceFilters(filters) {
  return filters.map((filter) => ({
    ...filter,
    backendColumn: dataSourceColumnBackendColumn[filter.column],
  }));
}

export function enrichConnectorFilters(filters) {
  return filters.map((filter) => ({
    ...filter,
    backendColumn: connectorsColumnToBackendColumn[filter.column] || filter.column,
  }));
}

export function getConnectorBackendKeyForOrderBy(orderBy) {
  return connectorsColumnToBackendColumn[orderBy] || orderBy;
}

export function enrichConnectionFilters(filters) {
  return filters.map((filter) => ({
    ...filter,
    backendColumn: connectionsColumnToBackendColumn[filter.column],
  }));
}

export function enrichUsersFilters(filters) {
  return filters.map((filter) => ({
    ...filter,
    backendColumn: usersColumnToBackendColumn[filter.column],
  }));
}

export function enrichPowerBiFilters(filters) {
  return filters.map((filter) => ({
    ...filter,
    backendColumn: powerBiColumnToBackendColumn[filter.column],
  }));
}

export function getConnectionBackendKeyForOrderBy(orderBy) {
  return connectionsColumnToBackendColumn[orderBy] || orderBy;
}

export function getUsersBackendKeyForOrderBy(orderBy) {
  return usersColumnToBackendColumn[orderBy];
}

export function getPowerBiBackendKeyForOrderBy(orderBy) {
  return powerBiColumnToBackendColumn[orderBy];
}

export function enrichAggregationsFilters(filters) {
  return filters.map((filter) => ({
    ...filter,
    backendColumn: aggregationsColumnToBackendColumn[filter.column],
  }));
}

export function getAggregationsBackendKeyForOrderBy(orderBy) {
  return aggregationsColumnToBackendColumn[orderBy];
}

export function enrichApplicationFilters(filters) {
  return filters.map((filter) => ({
    ...filter,
    backendColumn: applicationsColumnToBackendColumn[filter.column],
  }));
}

export function getApplicationBackendKeyForOrderBy(orderBy) {
  return applicationsColumnToBackendColumn[orderBy];
}

export function mapSeverityFromCode(severity) {
  switch (severity.toString().toLowerCase()) {
    case '3':
      return 'Critical';
    case '2':
      return 'High';
    case '1':
      return 'Medium';
    case '0':
      return 'Low';
    case '-1':
      return 'Info';
    default:
      return severity;
  }
}

export const mapConnectorFilterValue = (column, value) => {
  switch (column) {
    case 'firstSeen':
      return new Date(value).toISOString();
    case 'type':
      return value.toUpperCase();
    default:
      return value;
  }
};

export const mapFilterValue = (column, value) => {
  switch (column) {
    case 'inProduction':
      return value.toString().toLowerCase();
    case 'creationTime':
    case 'updateTime':
    case 'createdAt':
    case 'lastChange':
    case 'lastChangeTime':
    case 'closedAt':
    case 'lastActivity':
      return new Date(value).toISOString();
    case 'status':
      return snakeCase(value).toUpperCase();
    case 'severity':
      switch (value) {
        case 'Info':
          return '-1';
        case 'Low':
          return '0';
        case 'Medium':
          return '1';
        case 'High':
          return '2';
        case 'Critical':
          return '3';
        default:
          return value;
      }
    case 'active':
    case 'resourceActive':
    case 'guest':
    case 'public':
    case 'sharedWithOrg':
    default:
      return value;
  }
};

export function mapConnectorFilter(filter) {
  const { column, value, type } = filter;

  if (column.toLowerCase() === 'status') {
    const filterValue = value;
    const isTrue = type === IS ? 'true' : 'false';

    const createFilterObject = (val) => ({
      column: val.toLowerCase(),
      type: IS,
      value: isTrue,
    });

    return filterValue.includes(OR_OPERATOR)
      ? filterValue.split('_NOKODOR_').map(createFilterObject)
      : createFilterObject(filterValue);
  }

  return filter;
}

export function mapInsightFilter(filter) {
  switch (filter.column) {
    case 'resolved': {
      return {
        column: 'active',
        type: IS,
        value: filter.value === 'true' ? 'false' : 'true',
      };
    }
    default: {
      return filter;
    }
  }
}

function mapFilter(filter) {
  switch (filter.column) {
    case 'dataAccess': {
      if (filter.value === 'None') {
        return {
          column: filter.column,
          type: IS_NULL,
          value: 'true',
        };
      }
      return {
        column: filter.column,
        type: filter.type,
        value: filter.value,
      };
    }
    default: {
      return filter;
    }
  }
}

export function mapFiltersToParams(filters, { mapFilterFunction = mapFilter, mapValueFunction = mapFilterValue } = {}) {
  const params = new URLSearchParams();

  const mapAndAppend = ({ type, backendColumn, column, value }) => {
    const operation = mapFiltersTypeToOperation[camelCase(type)];
    const col = backendColumn ?? column;
    const val = value
      .toString()
      .split(OR_OPERATOR)
      .map((v) => mapValueFunction(col, v))
      .join(OR_OPERATOR);
    params.append(`${col}[${operation}]`, val);
  };

  filters.map(mapFilterFunction).forEach((mappedFilter) => {
    if (Array.isArray(mappedFilter)) {
      mappedFilter.forEach(mapAndAppend);
    } else {
      mapAndAppend(mappedFilter);
    }
  });

  return params;
}

export function mapPropertiesToParams(filters, orderBy, order, page, { mapFilterFunction, mapValueFunction } = {}) {
  const filtersParams = mapFiltersToParams(filters, { mapFilterFunction, mapValueFunction });

  if (orderBy && order && page) {
    filtersParams.append('orderBy', orderBy);
    filtersParams.append('order', order);
    filtersParams.append('page', page);
    filtersParams.append('limit', DEFAULT_TABLE_PAGE_SIZE);
  }

  return filtersParams;
}
