import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import BooleanValue from '../../../components/boolean-value/BooleanValue';
import Table from '../../../components/table/Table';
import TableEmptyContent from '../../../components/table/TableEmptyContent';
import emptyApplicationsImg from '../../inventory/empty_applications.svg';
import {
  fetchConnectionsFiltersOptions,
  updateConnectionsFiltersAndRefetchSummaries,
} from '../../../redux/slicers/connectionsSlicer';
import { mapDate } from '../../../services/MapperUtils';
import ConnectionShareType, { ShareType } from '../ConnectionShareType';
import Tags from '../../../components/tags/Tags';
import {
  getAllConnections,
  getConnectionFilters,
  getConnectionsFiltersOptions,
  getTotalConnections,
  isConnectionsLoading,
} from '../../../redux/selector/connectionSelector';
import OrganizationAwareLink from '../../../components/organization-aware-link/OrganizationAwareLink';
import ConnectionStatus from '../ConnectionStatus';
import InternalLink from '../../../components/internal-link/InternalLink';
import ConnectorName from '../../../components/connector-name/ConnectorName';
import ManageTagsDropdown, { TagResourceType } from '../../data-source/manage-tags-dropdown/ManageTagsDropdown';
import { ColumnType } from '../../../components/table/ColumnOptions';
import PlatformName from '../../../components/platform-name/PlatformName';
import EllipsisText from '../../../components/ellipsis-text/EllipsisText';

export default function ConnectionsPage({ switchTab }) {
  const dispatch = useDispatch();
  const data = useSelector(getAllConnections);
  const isLoading = useSelector(isConnectionsLoading);
  const totalCount = useSelector(getTotalConnections);
  const filterOptions = useSelector(getConnectionsFiltersOptions);

  const { t } = useTranslation(['common', 'connections']);

  useEffect(() => {
    dispatch(fetchConnectionsFiltersOptions());
  }, []);

  const columns = useMemo(
    () => [
      {
        backendKey: 'name',
        width: '18%',
        type: ColumnType.STRING,
        accessor: 'name',
        Cell: ({ value, cell }) => (
          <OrganizationAwareLink to={`/data/${cell.row.original.id}?from=Data`} className="black-link">
            <ConnectorName name={value} connectorName={cell.row.original.connectorName} shouldEllipsis />
          </OrganizationAwareLink>
        ),
      },
      {
        backendKey: 'connectorName',
        width: '10%',
        type: ColumnType.STRING,
        accessor: (cell) => cell.connectorName,
        Cell: ({ value }) => (
          <div className="connector-type">
            <InternalLink
              shouldEllipsis
              text={value}
              url={`/data?tab=Connectors&name:is=${value}`}
              onClick={() => switchTab()}
              replace
            />
          </div>
        ),
      },
      {
        backendKey: 'shareType',
        width: '7%',
        type: ColumnType.STRING,
        accessor: (cell) => cell.shareType,
        Cell: ({ value }) => <ConnectionShareType type={value} />,
      },
      {
        backendKey: 'tags',
        width: '8%',
        accessor: (cell) => cell.tagsInfo,
        Cell: ({ value, cell }) =>
          value?.length ? (
            <Tags tags={value} tooltipAnchor={`tags-${cell.row.original.id}`} />
          ) : (
            <div className="gray">Untagged</div>
          ),
      },
      {
        backendKey: 'accountName',
        width: '8%',
        type: ColumnType.STRING,
        accessor: (cell) => cell.accountName,
        Cell: ({ value, cell }) => (
          <EllipsisText
            text={<PlatformName platform={cell.row.original.platformName} name={value} />}
            overrideTooltipText={value}
          />
        ),
      },
      {
        backendKey: 'creationTime',
        width: '9%',
        type: ColumnType.DATE,
        accessor: (cell) => cell.creationTime,
        Cell: ({ cell }) => <div className="gray">{mapDate(cell.value)}</div>,
      },
      {
        backendKey: 'updateTime',
        width: '9%',
        type: ColumnType.DATE,
        accessor: (cell) => cell.updateTime,
        Cell: ({ cell }) => <div className="gray">{mapDate(cell.value)}</div>,
      },
      {
        backendKey: 'environments',
        width: '11%',
        accessor: (cell) => cell.environments,
        Cell: ({ value, cell }) => {
          if (!value?.length) {
            return <div className="gray">N/A</div>;
          }

          const tags = value.map((tag) => ({ name: tag }));
          return <Tags tags={tags} tooltipAnchor={`environment-${cell.row.original.id}`} />;
        },
      },
      {
        backendKey: 'inProduction',
        width: '10%',
        type: ColumnType.BOOLEAN,
        accessor: (cell) => cell.inProduction,
        Cell: ({ value }) => <BooleanValue isTrue={value} />,
        sortType: (rowA, rowB) => {
          const a = rowA.original.inProduction;
          const b = rowB.original.inProduction;
          return b - a; // reverse order so true is first
        },
      },
      {
        backendKey: 'applicationCount',
        type: ColumnType.NUMBER,
        width: '8%',
        accessor: (cell) => cell.applicationCount,
        Cell: ({ value, cell }) => (
          <InternalLink url={`/applications?connectionId:is=${cell.row.original.id}`} text={value} />
        ),
      },
      {
        backendKey: 'connectedStatus',
        type: ColumnType.STRING,
        width: '8%',
        accessor: (cell) => cell.connectedStatus,
        Cell: ({ value }) => <ConnectionStatus connectedStatus={value} />,
      },
    ],
    [],
  );

  const sortBy = [
    {
      id: 'Apps',
      desc: true,
    },
  ];

  return (
    <div className="connection-page">
      <Table
        columns={columns}
        data={data}
        options={{ showFilter: true, showHeaders: true }}
        type="connections"
        sortBy={sortBy}
        exportPath="connections/summary/csv"
        exportFileName="Nokod Connections"
        fetchDataWithFilters={(filters, orderBy, order, page) => {
          dispatch(updateConnectionsFiltersAndRefetchSummaries({ filters, orderBy, order, page }));
        }}
        getFiltersSelector={getConnectionFilters}
        customFilters={[]}
        contextualActions={[
          (selectedData) => (
            <ManageTagsDropdown
              useSimpleButton={false}
              key="tags"
              data={data}
              selectedResources={selectedData}
              tagResourceType={TagResourceType.Connection}
            />
          ),
        ]}
        onRowSelected={() => false}
        isRowDisabled={(conn) => conn.shareType === ShareType.None}
        getElementId={(connection) => connection.id}
        filtersOptions={filterOptions}
        totalCount={totalCount}
        isLoading={isLoading}
        emptyState={
          <TableEmptyContent
            img={emptyApplicationsImg}
            mainText={t('noConnections', { ns: 'connections' })}
            secondaryText={t('tables.makeDifferentFilterSelection')}
          />
        }
        defaultSearchColumn="name"
        defaultSearchColumnName={t('tables.connections.columns.name')}
      />
    </div>
  );
}
