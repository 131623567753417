import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import NotificationPolicyCreateDrawer from './NotificationPolicyCreateDrawer';
import BlurredDrawer from '../../../components/blurred-drawer/BlurredDrawer';
import { mapDate } from '../../../services/MapperUtils';
import { StyledSwitch } from '../../../components/StyledSwitch';
import TableEmptyContent from '../../../components/table/TableEmptyContent';
import emptyApplicationsImg from '../../inventory/empty_applications.svg';
import Table from '../../../components/table/Table';
import DeleteNotificationPolicyModal from './DeleteNotificationPolicyModal';
import NotificationPolicyFilters from './NotificationPolicyFilters';
import ToggleNotificationPolicyModal from './ToggleNotificationPolicyModal';
import { NotificationChannelName } from './NotificationChannelName';
import Toast from '../../../components/toast/Toast';
import {
  createNotificationPolicyError,
  editNotificationPolicyError,
} from '../../../redux/selector/notificationSelector';

const ModalType = Object.freeze({
  TOGGLE: 'toggle',
  DELETE: 'delete',
});

function EmptyPolicy() {
  const { t } = useTranslation(['settings']);
  return (
    <div className="notification-policy-empty">
      <div className="notification-policy-empty-title">{t('notifications.policiesTable.empty')}</div>
      <div className="notification-policy-empty-text">{t('notifications.policiesTable.emptyDescription')}</div>
    </div>
  );
}

export default function NotificationPolicyTable({ policies, isLoading }) {
  const { t } = useTranslation(['settings']);

  const [isNew, setIsNew] = useState(false);
  const error = useSelector(isNew ? createNotificationPolicyError : editNotificationPolicyError);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedPolicyId, setSelectedPolicyId] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [toastOpen, setToastOpen] = useState(false);
  const toastTitle = t(`notifications.policiesTable.${isNew ? 'create' : 'edit'}${error ? 'Failed' : 'Success'}`, {
    name: policies.find((policy) => policy.id === selectedPolicyId)?.name,
  });

  const columns = useMemo(
    () => [
      {
        Header: ' ',
        backendKey: 'active',
        accessor: 'isActive',
        width: '5%',
        Cell: ({ value, cell }) => (
          <StyledSwitch
            checked={value}
            onClick={() => {
              setSelectedPolicyId(cell.row.original.id);
              setModalType(ModalType.TOGGLE);
            }}
          />
        ),
      },
      {
        Header: t('notifications.policiesTable.columns.name'),
        accessor: 'name',
        width: '15%',
      },
      {
        Header: t('notifications.policiesTable.columns.creatorName'),
        accessor: 'creatorName',
        width: '10%',
      },
      {
        Header: t('notifications.policiesTable.columns.filters'),
        accessor: 'filters',
        width: '30%',
        Cell: ({ value }) => <NotificationPolicyFilters filters={value.filters} />,
      },
      {
        Header: t('notifications.policiesTable.columns.creationTime'),
        accessor: 'dbCreatedAt',
        width: '15%',
        Cell: ({ value }) => (value ? mapDate(value) : '-'),
      },
      {
        Header: t('notifications.policiesTable.columns.associatedChannels'),
        accessor: 'notificationChanelPolicies',
        width: '20%',
        Cell: ({ value }) =>
          (value || [])
            .map((policy) => policy.notificationChannel)
            .map((channel) => <NotificationChannelName name={channel.name} type={channel.type} key={channel.id} />),
      },
      {
        Header: t('notifications.policiesTable.columns.reminder'),
        accessor: 'reminderInterval',
        width: '5%',
        Cell: ({ value, cell }) =>
          !!value && <CheckIcon className="reminder-check" data-testid={`reminder-check-${cell.row.original.name}`} />,
      },
      {
        Header: ' ',
        accessor: 'id',
        Cell: ({ value, cell }) => (
          <div className="notification-actions" data-testid={`policy-actions-${cell.row.original.name}`}>
            <EditIcon
              data-testid={`policy-edit-${cell.row.original.name}`}
              className="notification-actions-edit"
              onClick={() => {
                setSelectedPolicyId(value);
                setIsNew(false);
                setDrawerOpen(true);
              }}
            />
            <DeleteOutlineOutlinedIcon
              data-testid={`policy-delete-${cell.row.original.name}`}
              className="notification-actions-delete"
              onClick={() => {
                setSelectedPolicyId(value);
                setModalType(ModalType.DELETE);
              }}
            />
          </div>
        ),
      },
    ],
    [],
  );

  const hasPolicies = policies.length || isLoading;
  const sortBy = [{ id: 'name', desc: false }];

  return (
    <div className="notifications-settings-table" data-testid="notification-policies-table">
      <div className="add-new-item">
        <div className="add-new-item-title">{t('notifications.policies')}</div>
        <div
          className="add-new-item-button"
          data-testid="add-new-policy"
          onClick={() => {
            setIsNew(true);
            setDrawerOpen(true);
          }}
        >
          + {t('notifications.policiesTable.addNew')}
        </div>
      </div>
      <DeleteNotificationPolicyModal
        modalOpen={modalType === ModalType.DELETE}
        closeModal={() => setModalType(null)}
        policyId={selectedPolicyId}
      />
      <ToggleNotificationPolicyModal
        modalOpen={modalType === ModalType.TOGGLE}
        closeModal={() => setModalType(null)}
        policyId={selectedPolicyId}
      />
      <Toast open={toastOpen} setOpen={setToastOpen} isSuccess={!error} title={toastTitle} />
      <BlurredDrawer open={drawerOpen} setOpen={setDrawerOpen} onClose={() => setSelectedPolicyId(null)}>
        <NotificationPolicyCreateDrawer
          policyId={selectedPolicyId}
          closeDrawer={() => {
            setDrawerOpen(false);
            setSelectedPolicyId(null);
          }}
          setToastOpen={setToastOpen}
        />
      </BlurredDrawer>
      {hasPolicies ? (
        <Table
          columns={columns}
          data={policies}
          totalCount={policies.length}
          options={{ showFilter: false, showPagination: false, showSort: true, usePaginationSearchParam: false }}
          type="notification-policies"
          shouldScrollOnPagination={false}
          sortBy={sortBy}
          initialFilters={[]}
          customFilters={[]}
          isRowDisabled={() => false}
          getElementId={(app) => app.id}
          isLoading={isLoading}
          classNames="card-view-table"
          backendPagination={false}
          pageSize={10}
          emptyState={<TableEmptyContent img={emptyApplicationsImg} mainText={t('notifications.policies.empty')} />}
        />
      ) : (
        <EmptyPolicy />
      )}
    </div>
  );
}
