import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LinearProgress } from '@mui/material';
import Button from '../../../../../components/buttons/button/Button';
import CloseableModal from '../../../../../components/modal/ClosableModal';
import { createPowerPlatformEnvironments } from '../../../../../redux/slicers/accountSlicer';
import {
  getAccountById,
  getPowerPlatformCompletedEnvsCount,
  getPowerPlatformEnvironmentsErrorMessage,
  isPowerPlatformEnvironmentsLoading,
} from '../../../../../redux/selector/accountsSelector';
import Loader from '../../../../../components/loader/Loader';
import PowerPlatformIntegrationError from '../PowerPlatformIntegrationError';
import { getTenantNameFromUrl } from '../../../../../services/OrgNameService';

export default function PowerPlatformIntegrationEnvironmentConfirmationModal({ open, setOpen, envsToIntegrate }) {
  const { accountId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const relevantAccount = useSelector(getAccountById(accountId));
  const isLoading = useSelector(isPowerPlatformEnvironmentsLoading);
  const error = useSelector(getPowerPlatformEnvironmentsErrorMessage);
  const completedEnvironments = useSelector(getPowerPlatformCompletedEnvsCount);
  const { user } = useAuth0();
  const { t } = useTranslation(['onboarding', 'common']);

  const progress = (completedEnvironments.length / envsToIntegrate.length) * 100;

  const isConditionalAccessError = error && error.includes('AADSTS53003:');

  const { clientId, platformTenantId } = relevantAccount?.data || {};

  async function dispatchCreateEnvironments() {
    await dispatch(
      createPowerPlatformEnvironments({
        accountId,
        environments: envsToIntegrate,
        clientId,
        platformTenantId,
        auditUserName: user.name,
        auditUserEmail: user.email,
      }),
    );
    setOpen(false);
    navigate(`/${getTenantNameFromUrl()}/portal/settings/platforms/power-platform/${accountId}/complete`);
  }

  return (
    <CloseableModal open={open} closeModal={() => setOpen(false)}>
      <div className="title">
        {isLoading
          ? t('environmentIntegration.connectingEnvironments', { ns: 'onboarding' })
          : t('general.areYouSure', { ns: 'common' })}
      </div>
      {isLoading && (
        <>
          <Loader />
          <LinearProgress variant="determinate" value={progress} />
          <div className="center">
            {t('environmentIntegration.loaderMessage', {
              completedEnvironments: completedEnvironments.length,
              envsToIntegrate: envsToIntegrate.length,
            })}
          </div>
        </>
      )}
      {error && !isConditionalAccessError && (
        <div className="error centered">
          <div className="emoji">💔</div>
          <div>{t('environmentIntegration.environmentsIntegrationFailed')}</div>
          <div>{error}</div>
        </div>
      )}
      {isConditionalAccessError && (
        <div className="error centered">
          <div className="emoji">💔</div>
          <PowerPlatformIntegrationError isEnvironmentError />
        </div>
      )}
      {!isLoading && !error && (
        <>
          <div className="text-container">
            <div className="text">{t('environmentIntegration.confirmation', { count: envsToIntegrate.length })}</div>
            <div className="text">{t('environmentIntegration.integrationProcessMessage')}</div>
          </div>
          <div className="buttons-row">
            <Button isFilled={false} text={t('general.cancel', { ns: 'common' })} onClick={() => setOpen(false)} />
            <Button isFilled text={t('general.approve', { ns: 'common' })} onClick={dispatchCreateEnvironments} />
          </div>
        </>
      )}
    </CloseableModal>
  );
}
